define("discourse/plugins/discourse-topic-bump/discourse/initializers/extend-for-topic-bump", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse/lib/show-modal", "I18n", "discourse/plugins/discourse-topic-bump/discourse/components/modal/topic-bump-modal"], function (_exports, _pluginApi, _ajax, _showModal, _I18n, _topicBumpModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  async function bumpTopic(topic, container) {
    const siteSettings = container.lookup("site-settings:main");
    const dialog = container.lookup("service:dialog");
    if (siteSettings.topic_bump_autobump_enabled) {
      let modal = container.lookup("service:modal");
      modal.show(_topicBumpModal.default, {
        model: {
          topic: topic
        }
      });
      return;
    }
    try {
      let result = await (0, _ajax.ajax)(`/t/${topic.id}/bump`, {
        type: "POST"
      }).then(data => {
        if (data.msg) {
          dialog.alert(_I18n.default.t("topic_bump.bump_topic_button.bumped"));
        }
      });
    } catch (e) {
      const xhr = e.xhr || e.jqXHR;
      if (xhr && xhr.responseJSON) {
        if (xhr.responseJSON.error_type == 'rate_limit') {
          dialog.alert(_I18n.default.t("topic_bump.bump_topic_button.rate_limit_error"));
          return;
        }
        if (xhr.responseJSON.error_type == 'invalid_access') {
          dialog.alert(_I18n.default.t("topic_bump.bump_topic_button.error"));
          return;
        }
      }
      dialog.alert(_I18n.default.t("topic_bump.bump_topic_button.error"));
    }
  }
  function initializeWithApi(api, container) {
    api.addPostSmallActionIcon("bumped", "fire-alt");
    api.registerTopicFooterButton({
      id: "topic_bump",
      icon: "fire-alt",
      priority: 240,
      label: "topic_bump.bump_topic_button.label",
      title: "topic_bump.bump_topic_button.title",
      action() {
        bumpTopic(this.topic, container);
      },
      dropdown() {
        return this.site.mobileView;
      },
      classNames: ["topic_bump"],
      dependentKeys: ["topic.see_bump_topic_button"],
      displayed() {
        return this.topic.see_bump_topic_button;
      }
    });
  }
  var _default = _exports.default = {
    name: "extend-for-topic-bump",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.topic_bump_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.28", api => initializeWithApi(api, container));
    }
  };
});